<template>
  <div class="pt-6 px-4">
    <div class="w-full grid gap-4 pb-6 all-settings">
      <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 2xl:col-span-2">
        <ul>
          <li>
            <div class="w-full grid gap-4 pb-6 grid-cols-12 settings-item">
              <div class="col-span-8">
                <h2>Personal Information</h2>
                <p class="text-sm">Upgrade your personal information here</p>
              </div>
              <div class="col-span-4">
                <button class="bg-transparent font-semibold py-2 px-4 border hover:border-black rounded" @click="pii = !pii">
                  <font-awesome-icon :icon="['fas', 'pencil']" />
                </button>
              </div>
              <div class="grid grid-cols-2 mb-4 gap-4 settings col-span-12" v-if="pii">
                <div class="w-full col-auto">
                  <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="name">Name</label>
                    <input
                      class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      v-model="consultant.name"
                      type="text"
                      id="name"
                      name="name"
                      v-validate="'required'"
                      placeholder="Name" />
                    <span class="block text-red-500 italic text-left">{{ errors.first('name') }}</span>
                  </div>

                  <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="country">Country</label>
                    <select
                      v-model="consultant.country"
                      id="country"
                      name="country"
                      v-validate="'required'"
                      class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                      <option value="" disabled>Select your country</option>
                      <option v-for="country in countries" :key="country.name" :value="country.name">{{ country.name }}</option>
                    </select>
                    <span class="block text-red-500 italic text-left">{{ errors.first('country') }}</span>
                  </div>

                  <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="contact-no">Contact No</label>
                    <input
                      class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      v-model="consultant.contactNo"
                      type="text"
                      v-validate="'required|mobile_phone'"
                      id="contact-no"
                      data-vv-as="contact no"
                      name="contactNo"
                      placeholder="Contact No" />
                    <span class="block text-red-500 italic text-left">{{ errors.first('contactNo') }}</span>
                  </div>

                  <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="tagline">TagLine</label>
                    <input
                      class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      v-model="consultant.tagline"
                      type="text"
                      id="tagline"
                      name="tagline"
                      v-validate="'required'"
                      placeholder="Tagline" />
                    <span class="block text-red-500 italic text-left">{{ errors.first('tagline') }}</span>
                  </div>

                  <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="email">Email</label>
                    <input
                      class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      v-model="consultant.email"
                      type="text"
                      id="email"
                      v-validate="'required|email'"
                      name="email"
                      placeholder="Email" />
                    <span class="block text-red-500 italic text-left">{{ errors.first('email') }}</span>
                  </div>

                  <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="description">Description</label>
                    <input
                      class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      v-model="consultant.description"
                      type="text"
                      id="description"
                      name="description"
                      v-validate="'required'"
                      placeholder="Description" />
                    <span class="block text-red-500 italic text-left">{{ errors.first('description') }}</span>
                  </div>

                  <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="dp">Display Picture</label>
                    <input
                      class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      type="file"
                      name="dp"
                      id="dp"
                      data-vv-as="display picture"
                      v-validate="'mimes:image/*'"
                      @change="uploadDP" />
                    <span class="block text-red-500 italic text-left">{{ errors.first('dp') }}</span>
                  </div>
                </div>
                <div class="w-full col-auto">
                  <div class="h-full">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="map">Location</label>
                    <map-view id="map" :center="center" :zoomControl="true" :dragging="true" :scrollWheelZoom="true" @center-changed="updateCenter">
                      <template v-slot:markers>
                        <l-marker :draggable="true" :lat-lng.sync="position" />
                      </template>
                      <template v-slot:control>
                        <l-control position="bottomleft">
                          <button class="bg-white border border-black p-2 rounded" @click.prevent="locateMe">
                            <font-awesome-icon :icon="['fas', 'location-crosshairs']" />
                          </button>
                        </l-control>
                      </template>
                    </map-view>
                  </div>
                </div>
                <button class="border text-white bg-green-600 border-green-800 py-2 px-6 rounded font-normal text-sm mt-5" @click.prevent="updateInfo">Save</button>
              </div>
            </div>

            <div class="w-full grid gap-4 pb-6 grid-cols-12 settings-item">
              <div class="col-span-8">
                <h2>Consultant Zone</h2>
                <p class="text-sm">Update service related information</p>
              </div>
              <div class="col-span-4">
                <button class="bg-transparent font-semibold py-2 px-4 border hover:border-black rounded" @click="consultant_zone = !consultant_zone">
                  <font-awesome-icon :icon="['fas', 'pencil']" />
                </button>
              </div>
              <div class="grid grid-cols-2 mb-4 gap-4 settings col-span-12" v-if="consultant_zone">
                <div class="w-full col-auto">
                  <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="serviceName">Name of your service</label>
                    <input
                      class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      v-model="serviceName"
                      name="serviceName"
                      type="text"
                      v-validate="'required'"
                      placeholder="Name of your service" />
                    <span class="block text-red-500 italic text-left">{{ errors.first('serviceName') }}</span>
                  </div>

                  <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="category">Category</label>
                    <select
                      v-model="category"
                      name="category"
                      v-validate="'required'"
                      class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                      <option value="null" disabled>Please Select</option>
                      <option v-for="category in categories" :key="category.id" :value="category">{{ category.name }}</option>
                    </select>
                    <span class="block text-red-500 italic text-left">{{ errors.first('category') }}</span>
                  </div>

                  <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-5" for="category">What type of services you offer?</label>
                    <div class="grid grid-cols-3 gap-2" v-if="category !== null">
                      <button
                        :class="[service.selected ? 'text-white bg-blue-500 hover:bg-blue-700 border-blue-700 border rounded-full' : 'text-gray-500 hover:text-gray-700 bg-white']"
                        class="text-sm py-1 px-2"
                        v-for="service in category.subCategory"
                        :key="service"
                        @click.prevent="service.selected = !service.selected">
                        {{ service.label }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="w-full grid gap-4 pb-6 grid-cols-12 settings-item">
              <div class="col-span-8">
                <h2>Danger Zone</h2>
                <p class="text-sm">Terminate User Account</p>
              </div>
              <div class="col-span-4">
                <button class="bg-transparent font-semibold py-2 px-4 border hover:border-black rounded" @click="danger_zone = !danger_zone">
                  <font-awesome-icon :icon="['fas', 'pencil']" />
                </button>
              </div>
              <div class="grid grid-cols-2 mb-4 gap-4 settings col-span-12" v-if="danger_zone">
                <div class="w-full col-auto">
                  <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="name">Enter your current password</label>
                    <input
                      class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      v-model="accountTermination.password"
                      type="password"
                      name="at_password"
                      data-vv-as="password"
                      v-validate="'required'"
                      placeholder="Password" />
                    <span class="block text-red-500 italic text-left">{{ errors.first('at_password') }}</span>
                  </div>
                  <button class="border text-white bg-gray-600 border-gray-800 py-2 px-6 rounded font-normal mt-2 mb-4 text-sm" @click.prevent="requestAccountDeletion">
                    Request Account Deletion
                  </button>
                  <div class="mb-4" v-if="accountTermination.deletionRequestId != null">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="name">Otp Code</label>
                    <input
                      class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      v-model="accountTermination.otpCode"
                      type="number"
                      id="otpCode"
                      name="otpCode"
                      data-vv-as="otp code"
                      v-validate="'required|numeric'"
                      placeholder="OTP Code" />
                    <span class="block text-red-500 italic text-left">{{ errors.first('otpCode') }}</span>
                  </div>
                  <button
                    v-if="accountTermination.deletionRequestId != null"
                    class="border text-white bg-red-600 border-red-800 py-2 px-6 rounded font-normal mt-2 mb-4 text-sm"
                    @click.prevent="deleteAccount">
                    Delete Account
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="w-full grid grid-cols-12 gap-4 pb-6">
      <div class="col-span-7"></div>
    </div>

    <div class="w-full grid gap-4 pb-6 account-summery mt-12"></div>
  </div>
</template>

<script>
import countries from '@/plugins/country-regions';
import { LControl, LMarker } from 'vue2-leaflet';
import MapView from '@/components/MapView.vue';
import auth_service from '@/services/auth_service';
import consultant_service from '@/services/consultant_service';
import category_service from '@/services/category_service';

export default {
  name: 'consultant_profile',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    'map-view': MapView,
    LMarker,
    LControl,
  },
  data() {
    let user = this.$store.state.user;
    let consultant = {
      id: user.id,
      name: user.name,
      email: user.email,
      country: user.country,
      contactNo: user.extra.contact_no || '',
      serviceName: user.extra?.service_name || '',
      category: user.extra.category || '',
      services: user.extra.services || null,
      tagline: user.extra.tagline || '',
      description: user.extra.description || '',
      longitude: user.extra.longitude || 0,
      latitude: user.extra.latitude || 0,
    };
    return {
      files: null,
      center: [0, 0],
      pii: false,
      consultant_zone: false,
      danger_zone: false,
      accountTermination: {
        password: null,
        deletionRequestId: null,
        otpCode: null,
      },
      consultant: Object.assign({}, consultant),
      countries: countries,
      serviceName: '',
      category: null,
      categories: [],
    };
  },
  computed: {
    position: {
      get() {
        return [this.consultant.latitude, this.consultant.longitude];
      },
      set(e) {
        this.consultant.latitude = e.lat;
        this.consultant.longitude = e.lng;
      },
    },
  },
  methods: {
    updateCenter(e) {
      this.center = [e.lat, e.lng];
    },
    locateMe() {
      this.center = [this.consultant.latitude, this.consultant.longitude];
    },
    updateInfo() {
      consultant_service
        .update(this.consultant)
        .then(() => {
          this.$store.dispatch('setUser', this.consultant);
          this.$msg.success({ title: 'Profile data update', message: 'Profile updated successfully' });
        })
        .catch(() => this.$msg.error({ title: 'Profile data update', message: 'Unable to update profile data' }));
    },
    uploadDP(e) {
      this.$s3
        .uploadDp(this.consultant.id, e.target.files[0])
        .then(() => {
          this.$emit('update:dp');
          this.$msg.success({ title: 'Display picture update', message: 'Display picture changed successfully' });
        })
        .catch(() => {
          this.$msg.error({ title: 'Display picture update', message: 'Unable to change display picture' });
        });
    },
    requestAccountDeletion() {
      auth_service
        .requestAccountDeletion(this.accountTermination.password)
        .then(({ data }) => {
          this.$msg.success({ title: 'Account deletion request', message: 'An OTP code has been sent to your email' });
          this.accountTermination.deletionRequestId = data.requestId;
        })
        .catch(({ response }) => this.$msg.error({ title: 'Account deletion request', message: response.data.error }));
    },
    deleteAccount() {
      auth_service
        .deleteAccount(this.accountTermination.deletionRequestId, this.accountTermination.otpCode)
        .then(() => auth_service.signOut())
        .then(() => (this.consultant = null))
        .then(() => this.$msg.success({ title: 'Account deletion', message: 'Account deleted successfully' }))
        .catch(({ response }) => this.$msg.error({ title: 'Account deletion', message: response.data.error }));
    },
  },
  created() {
    this.locateMe();
  },
  mounted() {
    category_service.getCategories().then(({ data }) => {
      let m = new Map();
      data.categories.forEach((item) => {
        if (m.get(item.breadcrumb) === undefined) {
          m.set(item.breadcrumb, []);
        }
        let arr = m.get(item.breadcrumb);
        arr.push(item.name);
        m.set(item.breadcrumb, arr);
      });
      // Logging map object to console
      let categories = [];
      m.forEach((values, key) => {
        categories.push({ name: key, subCategory: values });
      });
      this.categories = categories;
    });
  },
};
</script>
